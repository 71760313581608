<template>
  <div class="meResume">
    <img
      class="img pointer"
      v-if="typearr.includes('general') || typearr.includes('center')"
      src="../../assets/image/1.png"
      @click="general"
      alt=""/>
    <img
      class="img pointer"
      v-if="typearr.includes('logistics')"
      src="../../assets/image/2.png"
      alt=""
      @click="logistics"/>
    <img
      class="img pointer"
      v-if="typearr.includes('delivery')"
      src="../../assets/image/3.png"
      alt=""
      @click="delivery"/>
    <img
      class="img pointer"
      v-if="typearr.includes('tdc')"
      src="../../assets/image/4.png"
      alt=""
      @click="tdc"/>
  </div>
</template>
<script>
export default {
  name: "meResume",
  components: {},
  data() {
    return {
      typearr: [],
      info: "",
    };
  },
  created() {
    this.info = JSON.parse(sessionStorage.getItem("userInfo"));
    this.typearr = this.info.business_type_code.split(",");
  },
  methods: {
    // 总控
    general() {
      // 登录人身份
      sessionStorage.setItem("manageIsdentity", 'general');
      this.$api.general.getRoleMenu().then((res) => {
        // 缓存登录人权限菜单
        sessionStorage.setItem("menu", JSON.stringify(res.data));

        if (res.data[0].children && res.data[0].children.length > 0) {
          this.$router.push({path: res.data[0].children[0].path});
        } else {
          this.$router.push({path: res.data[0].path});
        }
      });
    },
    // 集配
    logistics() {
      // 登录人身份
      sessionStorage.setItem("manageIsdentity", 'logistics');
      this.$api.logistics.getRoleMenu().then((res) => {
        // 缓存登录人权限菜单
        sessionStorage.setItem("menu", JSON.stringify(res.data));

        if (res.data[0].children && res.data[0].children.length > 0) {
          this.$router.push({path: res.data[0].children[0].path});
        } else {
          this.$router.push({path: res.data[0].path});
        }
      });
    },
    // 自提点
    delivery() {
      // 登录人身份
      sessionStorage.setItem("manageIsdentity", 'delivery');
      this.$api.delivery = this.$api.copyDelivery
      this.$api.delivery.getRoleMenu().then((res) => {
        // 缓存登录人权限菜单
        sessionStorage.setItem("menu", JSON.stringify(res.data));
        
        if (res.data[0].children && res.data[0].children.length > 0) {
          this.$router.push({path: res.data[0].children[0].path});
        } else {
          this.$router.push({path: res.data[0].path});
        }
      });
    },
    // 城市仓
    tdc() {
      // 登录人身份
      sessionStorage.setItem("manageIsdentity", 'tdc');
      this.$api.delivery = this.$api.tdc
      this.$api.tdc.getRoleMenu().then((res) => {
        // 缓存登录人权限菜单
        sessionStorage.setItem("menu", JSON.stringify(res.data));
        console.log(this.$api)
        
        console.log(this.$api)
        if (res.data[0].children && res.data[0].children.length > 0) {
          this.$router.push({path: res.data[0].children[0].path});
        } else {
          this.$router.push({path: res.data[0].path});
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.meResume {
  height: 100%;
  background: #f5f6f8;
  padding: 50px 15px 50px 44px;

  .img {
    width: 500px;
    height: 168px;
    margin-right: 37px;
  }
}
</style>
