var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "meResume" }, [
    _vm.typearr.includes("general") || _vm.typearr.includes("center")
      ? _c("img", {
          staticClass: "img pointer",
          attrs: { src: require("../../assets/image/1.png"), alt: "" },
          on: { click: _vm.general },
        })
      : _vm._e(),
    _vm.typearr.includes("logistics")
      ? _c("img", {
          staticClass: "img pointer",
          attrs: { src: require("../../assets/image/2.png"), alt: "" },
          on: { click: _vm.logistics },
        })
      : _vm._e(),
    _vm.typearr.includes("delivery")
      ? _c("img", {
          staticClass: "img pointer",
          attrs: { src: require("../../assets/image/3.png"), alt: "" },
          on: { click: _vm.delivery },
        })
      : _vm._e(),
    _vm.typearr.includes("tdc")
      ? _c("img", {
          staticClass: "img pointer",
          attrs: { src: require("../../assets/image/4.png"), alt: "" },
          on: { click: _vm.tdc },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }